import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import MediaQuery from "../../../layout/MediaQuery";
import { HybridLink } from "../../../layout/Footer";

const ExtendedLink = Styled(HybridLink)``;

const Container = Styled.div`
  width: 100%;
  padding: 8rem;
  background-color: ${(props) => props.theme.colors.gray.lighter}; 
  border-radius: 1rem;
  margin-bottom: 5rem;
  
  ${Media.lessThan("medium")`
    padding: 3rem;
  `}
`;

const ExtendedButton = Styled(Button)`
  font-size: 1.6rem;
  border-radius: 1rem;
  ${Media.lessThan("small")`
    width: 100%;
  `}
`;

export default ({ headline, description, link, cta }) => (
  <Container>
    <Headline>{headline}</Headline>
    <Description>{description}</Description>
    <ExtendedLink to={link}>
      <ExtendedButton type="submit" variant="black" solid>
        <MediaQuery lessThan="medium">{cta}</MediaQuery>
        <MediaQuery greaterThan="medium">{cta} to learn more</MediaQuery>
      </ExtendedButton>
    </ExtendedLink>
  </Container>
);

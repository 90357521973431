import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import Content from "../../components/layout/Content/Content";
import ContactHeader from "../../components/pages/contact/ContactHeader";
import Button from "../../components/buttons/Button";
import Shoutout from "../../components/pages/signup/Shoutout";
import FormGroup from "../../components/form/FormGroup";
import AboutStart from "../../components/pages/about/AboutStart";
import PressFeatured from "../../components/pages/press/PressFeatured";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 16rem 0 10rem 0;
  `}
  
  ${Media.lessThan("small")`
    margin: 10rem 0 5rem 0;
  `}
  
  ${FormGroup} {
    margin: 0 0 1.5rem 0;
  }
`;

const Columns = Styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;
  
  ${Media.lessThan("660px")`
    flex-direction: column-reverse;
    align-items: center;
    & > * {width: 100%}
  `}
  
  ${Media.lessThan("small")`
    margin-bottom: 3.5rem;
  `}
`;

const Column = Styled.div`
  position: relative;
  
  ${Media.lessThan("small")`
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.lessThan("medium")`
    width: 50%;
    
    &:first-of-type {
      margin-right: 3rem;
    }
    
    &:last-of-type {
      width: 32rem;
    }
    `}
   
  ${Media.lessThan("660px")`
    width: 100%;
    margin: 0 auto;
    
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.greaterThan("medium")`
    width: 45%;
    min-width: 0;
    padding: 0 3rem;
  `}

  ${Media.greaterThan("large")`
    padding: 0 6rem;
  `}
`;

const ColumnText = Styled(Column)`
  margin: 8rem 0 10rem 0;
  padding: 0;
  width: 100%;

  ${Media.greaterThan("medium")`
    display: flex;
    justify-content: space-evenly;
  `}
`;

const ExtendedButton = Styled(Button)`
  ${Media.lessThan("medium")`
    margin-bottom: 3rem;
    width: 100%;
  `}
`;

const NavButtonLink = Styled.a`
`;

const Press = ({ data }) => (
  <Layout>
    <Meta
      title="Press | Uizard Media Coverage & Press Inquiries | Uizard"
      description="Read past media coverage featuring our product, our research, and our team as we embark on our mission to democratize design for the world."
      url="/press/"
    />

    <Content>
      <Container>
        <ContactHeader
          headline="Press"
          tagline="Read past media coverage featuring our product, our research, and our team."
        />

        <PressFeatured />

        <AboutStart
          headline="Press Kit"
          description="The Uizard journey started as a machine learning research project called pix2code in 2017 in Copenhagen, Denmark.
          Uizard only fully formed as a company in early 2018 after a trip to San Francisco and a hacking session in a Mountain View garage—like a true Silicon Valley cliché."
          link="/press_kit.zip"
          cta="Download our Press Kit"
        />

        <ColumnText>
          <NavButtonLink href="/contact/">
            <ExtendedButton variant="primarylight" solid>
              Contact Us
            </ExtendedButton>
          </NavButtonLink>

          <NavButtonLink href="/blog/media-coverage-featuring-our-work/">
            <ExtendedButton variant="white">Media Coverage</ExtendedButton>
          </NavButtonLink>

          <NavButtonLink href="/blog/uizard-in-podcasts/">
            <ExtendedButton variant="white">Podcasts</ExtendedButton>
          </NavButtonLink>

          <NavButtonLink href="/research/">
            <ExtendedButton variant="white">Research</ExtendedButton>
          </NavButtonLink>
        </ColumnText>

        <Shoutout
          headline="Design effortlessly"
          description="Probably the easiest design tool you'll ever use"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </Container>
    </Content>
  </Layout>
);

export default Press;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

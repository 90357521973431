import Styled from "styled-components/macro";

const FormGroup = Styled.div`
  margin: 0 0 3rem 0;

  ${(props) =>
    props.smallMargin &&
    `
    margin-bottom: 1.5rem;
  `}

  ${(props) =>
    props.noMargin &&
    `
    margin-bottom: 0;
  `}
`;

export default FormGroup;

import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

const Container = Styled.div`
    text-align: center;
`;

const Tagline = Styled.span`
  display: block;
  font-size: 2.6rem;
  color: inherit;
  margin: 0 0 10rem 0;
  
  ${Media.lessThan("medium")`
    margin: 0 0 5rem 0;
    font-size: 2rem;
  `}
  
  ${Media.lessThan("small")`
    margin: 0 0 3rem 0;
  `}
`;

const Headline = Styled.h2`
  font-size: 3.6rem;
  font-weight: 700;
  color: inherit;
  line-height: 1.125;
  margin: 0 0 1.5rem 0;

  ${Media.greaterThan("medium")`
    font-size: 5.6rem;
    margin: 0 0 3rem 0;
  `}
`;

type ContactHeaderProps = {
  tagline: string;
  headline: string;
};

export default ({ tagline, headline }: ContactHeaderProps) => (
  <Container>
    <Headline as="h1">{headline}</Headline>
    <Tagline>{tagline}</Tagline>
  </Container>
);
